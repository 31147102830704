<template>
  <!-- <x-dialog :proxy="goodsSelectorDialog"> -->
  <!-- <el-tabs v-model="params.self_goods" v-if="!isJDSupplier" type="card" @tab-click="tabClick">
      <el-tab-pane v-for="item in fdList" :key="item.navigation_id" :label="item.navigation_name" :name="item.self_goods"
        :disabled="loading" />
    </el-tabs> -->

  <en-table-layout ref="tables" :table-data="tableData.data ? tableData.data : []" :row-key="row => row.id"
    :redundancy-height="50" @selection-change="selectionChange" @select="handleSelect" @select-all="handleSelectAll"
    @sort-change="handleSortChange">
    <!-- 企业跳站点时 -->
    <template>
      <template slot="toolbar">
        <el-form ref="toolbar_form" style="width: 100%;">
          <div style="padding: 0 22px 10px;">
            <el-form-item v-if="!(!this.MixinIsFormEnterprise && this.ispetroChina)" label="分销总价" prop="lowEnterPrice">
              <el-input style="width: 120px;" size="medium" :min="0" v-model="params.lowEnterPrice"
                placeholder="最低分销总价" />
              <span style="margin: 0 5px;">--</span>
              <el-input style="width: 120px;" size="medium" v-model="params.highEnterPrice" placeholder="最高分销总价" />
            </el-form-item>
            <el-form-item label="套餐价" prop="lowMktPrice">
              <el-input style="width: 100px;" size="medium" :min="0" v-model="params.lowComboPrice" placeholder="最低套餐价" />
              <span style="margin: 0 5px;">--</span>
              <el-input style="width: 100px;" size="medium" v-model="params.highComboPrice" placeholder="最高套餐价" />
            </el-form-item>
            <el-form-item label="创建时间" class="col-auto" size="small">
              <el-date-picker style="width: 220px" v-model="createTimeFilter" type="daterange" align="center"
                size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="timestamp" @change="createTimeFilterChange"></el-date-picker>
            </el-form-item>
            <el-form-item label="套餐类型" style="margin-bottom: 0;">
              <el-select v-model="params.comboType" style="width: 130px" clearable>
                <el-option value label="全部"></el-option>
                <el-option :value="1" label="京东"></el-option>
                <el-option :value="2" label="线下品"></el-option>
                <el-option :value="3" label="京东+线下品"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="礼包分类" style="margin-bottom:0">
              <el-cascader clearable style="width: 260px;" v-model="params.categoryPath" :options="giftsCategory.children"
                :props="giftsCategory.props" placeholder="请选择礼包分类" class="cas-select" />
            </el-form-item>

            <el-form-item label="区域偏好" style="margin-bottom:0">
              <el-input style="width:190px" placeholder="请输入关键词进行搜索" v-model.trim="params.labelNamesArea"
                clearable></el-input>
            </el-form-item>
            <el-form-item label="" class="col-auto">
              <el-input style="width: 280px" v-model.trim="params.keywords" placeholder="请输入关键词" clearable>
                <el-select style="width: 130px;" v-model="search_type" slot="prepend">
                  <el-option label="礼包名称" value="gift_name"></el-option>
                  <el-option label="前端礼包名称" value="web_gift_name"></el-option>
                  <el-option label="商品名称" value="goods_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="是否异常">
              <el-select v-model="params.normalStatus" style="width: 100px">
                <el-option value label="全部"></el-option>
                <el-option :value="0" label="异常"></el-option>
                <el-option :value="1" label="正常"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" size="small" @click="handleSearch({ initPageNo: true, saveParams: true })">
              搜索
            </el-button>
            <el-button v-if="!showChooseBtn" type="primary" style="margin-left: 5px;" size="small" @click="chooseAll">
              批量挑选
            </el-button>
            <div class="col"></div>
          </div>
        </el-form>
      </template>
    </template>
    <template slot="table-columns">
      <el-table-column v-if="!showChooseBtn" :reserve-selection="true" type="selection" width="40" />

      <el-table-column label="礼包图片" prop="thumbnail" width="80" class-name="goods-cover-wrapper" fixed>
        <template slot-scope="{row}">
          <el-popover placement="right" trigger="hover">
            <img :src="row.thumbnail" alt="" style="width: 300px">
            <!-- <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" /> -->
            <div slot="reference">
              <div style="position: relative;width: fit-content;margin: 0 auto;">
                <img :src="row.thumbnail" class="goods-cover" alt="" />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="礼包名称" prop="" show-overflow-tooltip fixed>
        <template slot-scope="scope">
          {{ scope.row.gift_name }}
        </template>
      </el-table-column>
      <el-table-column label="前端礼包名称" prop="" show-overflow-tooltip fixed>
        <template slot-scope="scope">
          {{ scope.row.web_gift_name }}
        </template>
      </el-table-column>
      <el-table-column label="礼包分类" :key="'category_name'" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.category_name }}
        </template>
      </el-table-column>
      <el-table-column label="商品种类" :key="'goods_kind_num'">
        <template slot-scope="scope">
          <!-- class="num" @click="handleComboGoodsNum(scope.row)" -->
          <span>
            {{
              scope.row.goods_kind_num
            }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-show="params.self_goods === '3' && $store.getters.isSupplierName">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column> -->
      <!-- <el-table-column label="套餐价" width="90" sortable="custom" prop="comboPrice">
        <template slot-scope="scope">
          {{ scope.row.combo_price | unitPrice('￥') }}
        </template>
      </el-table-column> -->
      <el-table-column label="会员总价" width="110" v-if="MixinIsFormEnterprise" sortable="memberPrice" prop="price">
        <template slot-scope="scope">
          {{ scope.row.sum_price | unitPrice('￥') }}
        </template>
      </el-table-column>

      <el-table-column label="分销总价" width="110" sortable="custom" prop="enterprisePrice"
        v-if="isSupplement === '0' && !(!this.MixinIsFormEnterprise && this.ispetroChina)">
        <template slot-scope="scope">
          {{ scope.row.enterprise_sum_price | unitPrice('￥') }}
        </template>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="分销利润率" width="130" prop="distribution_profit" sortable="custom"
        :key="'distribution_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>分销利润率</label>
            <div slot="content">
              分销利润率 = (分销总价-会员总价) /分销总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.distribution_profit ? scope.row.distribution_profit.toFixed(2) : 0
        }}%</template>
      </el-table-column>
      <el-table-column label="市场总价" width="110" sortable="custom" prop="marketPrice">
        <template slot-scope="scope">
          {{ scope.row.market_sum_price | unitPrice('￥') }}
        </template>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="市场利润率" width="130" prop="market_profit" sortable="custom"
        :key="'market_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>市场利润率</label>
            <div slot="content">
              市场利润率 = (市场总价-会员总价) /市场总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.market_profit ? scope.row.market_profit.toFixed(2) : 0 }}%</template>
      </el-table-column>
      <!-- <el-table-column label="毛利率" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="profit">
          <template slot-scope="scope">
            {{ (scope.row.enterprise_profit || 0) | unitCompare('%') }}
          </template>

          <template slot="header" width="90" slot-scope="{}">
            <el-tooltip effect="dark" content="毛利率 =（市场价 - 会员价）/ 市场价 * 100%" placement="top">
              <label class="cursor-pointer">毛利率</label>
            </el-tooltip>
          </template>
        </el-table-column> -->
      <el-table-column label="销售总价" width="110" :key="'shopPrice'" prop="shopPrice" sortable="custom">
        <template slot-scope="scope">
          <span>{{ scope.row.shop_sum_price | unitPrice('￥') }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="MixinIsFormEnterprise" label="销售利润率" width="130" prop="price_profit" sortable="custom"
        :key="'price_profit'">
        <template slot="header">
          <el-tooltip effect="dark" placement="top">
            <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>销售利润率</label>
            <div slot="content">
              销售利润率 = (销售总价-会员总价) /销售总价*100%
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope">{{ scope.row.price_profit ? scope.row.price_profit.toFixed(2) : 0 }}%</template>
      </el-table-column>
      <!-- <el-table-column label="利润率" v-if="MixinIsFormEnterprise" width="100" prop="profitMargin" sortable="custom"
        :key="'shop_profit'">
        <template slot-scope="scope">{{ scope.row.shop_profit.toFixed(2) || 0 }}%</template>
      </el-table-column> -->
      <el-table-column label="创建时间" width="160" prop="time" :key="'time'">
        <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
      <el-table-column label="套餐类型" width="160" prop="combo_type" :key="'combo_type'">
        <template slot-scope="scope">
          <span v-if="scope.row.combo_type == 1">京东</span>
          <span v-else-if="scope.row.combo_type == 2">线下品</span>
          <span v-else-if="scope.row.combo_type == 3">京东+线下品</span>
        </template>
      </el-table-column>
      <el-table-column label="区域偏好" width="160" show-overflow-tooltip prop="label_names_area" :key="'label_names_area'" />
      <el-table-column label="标签" width="160" show-overflow-tooltip prop="label_names_gift" :key="'label_names_gift'" />
      <el-table-column label="备注信息" :show-overflow-tooltip="true" width="200" prop="remark" />
      <el-table-column label="操作" v-if="showChooseBtn" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="choose(scope.row)">
            挑选
          </el-button>
        </template>
      </el-table-column>
    </template>

    <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
      :current-page="tableData.page_no" :page-size="tableData.page_size" :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout" background :total="tableData.data_total" :pager-count="13">
    </el-pagination>
  </en-table-layout>
  <!-- </x-dialog> -->
</template>

<script>
import mixin from './mixin';
// import EnTableLayout from '../../TableLayout/src/main';
import EnTableLayout from '../../../../../../../ui-components/TableLayout/src/main';
// import XDialog from '@/components/x-dialog/x-dialog';
// import XSortable from '@/components/x-sortable/x-sortable';
// import * as API_goods from "../../../src/api/goods";
import * as API_goods from "@/api/goods";
import statusErr from "@/assets/goods/statusErr.png";
export default {
  name: 'EnGoodsPickerFx',
  components: {
    EnTableLayout
  },
  mixins: [mixin],
  watch: {
  },
  mounted () {
  },
  methods: {
    getRowTagImage (row) {
      if (row.normal_status === 0) return statusErr
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === 'province') {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result)
        })
      } else if (type === 'city') {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result)
        })
      } else if (type === 'county') {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result)
        })
      } else if (type === 'town') {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result)
        })
      }
    },
    dataHandle (data) {
      const returnData = []
      const keys = Object.keys(data)
      const values = Object.values(data)
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        }
        returnData.push(data)
      })
      return returnData
    },
    /**
     * 查看商品
     */
    handleComboGoodsNum (row) {
      console.log(row)
      this.comboGoodsNumVisible = true
      this.comboGoodsNumTableData.data = []
      row.goods_volist.forEach(item => {
        const {
          web_thumbnail,
          supplier_name,
          goods_alias,
          goods_name,
          num,
          shop_goods_vo: {
            goods_do: { goods_source, thumbnail }
          }
        } = item
        this.comboGoodsNumTableData.data.push({
          goods_name,
          goods_alias,
          goods_source,
          num,
          web_thumbnail,
          thumbnail,
          supplier_name
        })
      })
    },
  }
};
</script>

<style lang="scss" >
.goods-selector-dialog {
  .el-tabs__header {
    margin: 0;
  }

  .el-scrollbar__view {
    height: 80vh;
  }
}
</style>
