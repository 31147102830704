/**
 * Created by Andste on 2018/5/28.
 */
import request from '@/utils/request'
import {domain} from '@/../ui-domain'
import {Foundation} from '@/../ui-utils'
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {$goodsCategory} from "@/pages/goods/services/goods-category";
import * as API_Login from '@/api/login';
import { getCnpcShopIds } from "@/api/account"
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
let provinceWatcher, cityWatcher, countyWatcher
const Params = {
  comboType: "", // 套餐类型
  normalStatus: void 0, // 是否异常
  // 礼包状态1 启用
  comboStatus: 1,
  // 市场价格大值
  highMktPrice: '',
  // 市场价格小值
  lowMktPrice: '',
  // 套餐价格大值
  lowComboPrice: '',
  // 套餐价格小值
  highComboPrice: '',
  // 分销价格大值
  highEnterPrice: '',
  // 分销价格小值
  lowEnterPrice: '',
  // 商品来源，0普通商品，1京东商品，2苏宁商品
  // goods_source: 1,
  // self_goods: '2',
  // 利润率大值
  profit_max: '',
  // 利润率小值
  profit_min: '',
  // 最低利润率
  shop_profit_min: '',
  // 最高利润率
  shop_profit_max: '',
  // 关键词
  keywords: '',
  // 商品名称
  keyword: '',
  // 商品编号
  goods_sn: '',
  // 页码
  page_no: 1,
  // 分页数
  page_size: 10,
  // 排序
  sort: '',
  category_path: '',
  // 创建开始时间
  create_time_start: '',
  // 创建结束时间
  create_time_end: '',
}
export default {
  props: {
    // 是否是自提
    delivery_method: {
      type: Number,
      default: ''
    },
    // 显示dialog
    show: {
      type: Boolean,
      default: false
    },
    // 显示每一行的挑选按钮
    showChooseBtn: {
      type: Boolean,
      default: false
    },
    // 默认数据
    defaultData: {
      type: Array,
      default: () => ([])
    },
    // 排除
    excludeData: {
      type: Array,
      default: () => ([])
    },
    // 商品列表
    goodsApi: {
      type: String,
      default: `seller/shopCombo/comboListTh`
    },
    // 京东商品分类
    jdCategoryApi: {
      type: String,
      default: `jd/category/depth/@depth`
    },
    // 是否套餐商品
    showCombo: {
      type: Number,
      default: 0
    },
    // 自有商品分类
    sellerCategoryApi: {
      type: String,
      default: `seller/goods/category/depth/@depth`
    },

    // 获取集市导航APi   京东自有==
    navigationsApi: {
      type: String,
      default: `seller/pages/market/site-navigations`
      // params: { client_type :'PC' }
    },
    // 是否补单
    isSupplement: {
      type: String,
      default: '0'
    },
    // 补单所在的店铺
    supplementShopId: {
      type: String | Number,
      default: ''
    }
  },
  data() {
    return {
      ispetroChina: false, // 是否是中石油商城
      goodsSelectorDialog: $xDialog.create({
        title: '礼包选择器',
        disableCancel: true,
        disableConfirm: true,
        width: '95vw',
        // wrapperClass: 'goods-selector-dialog',
        // eslint-disable-next-line no-return-assign, no-sequences
        afterDismiss: () => (this.$emit('close', true), this.params = {...Params}),
      }),
      maxNumber: Math.pow(10, 12),
      search_type: 'gift_name',
      params: {...Params},
      typeList: [
        {id: 0, type: '全部'},
        {id: 1, type: '有货'},
        {id: 2, type: '无货'},
      ],
      giftsCategory: $giftsCategory.create(),
      JDCategory: $goodsCategory.JD,
      categories: [],
      goodsSortList: [
        {
          label: '按时间正序',
          value: 'time_asc'
        },
        {
          label: '按时间倒序',
          value: 'time_desc'
        },
        {
          label: '按利润率从小到大',
          value: 'profit_asc'
        },
        {
          label: '按利润率从大到小',
          value: 'profit_desc'
        },
        {
          label: '按会员价从小到大',
          value: 'price_asc'
        },
        {
          label: '按会员价从大到小',
          value: 'price_desc'
        }
      ],
      chooseChangeList: [],
      chooseAllList: [],
      tableData: {},
      tableHeight: 0,
      /** 加载状态 */
      loading: false,
      /** 商品列表 */
      goodsList: [],
      /** 已选列表 */
      selectedGoods: {},
      dialogVisible: this.show,
      // 前台域名
      buyerDomain: domain.buyer_pc,
      canChangeVal: true,
      // 获取集市导航
      fdList: [],
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData: {
        province: '',
        city: '',
        county: '',
        town: '',
        jd_area_state: 0
      },
      quantityStatus: false,
      /** 查看商品数量，商品展示字段 */
      comboGoodsNumVisible: true,
      comboGoodsNumTableData: {},
      createTimeFilter: [], //  创建时间
      rules: {
        lowEnterPrice: [
          {validator: this.validateLowEnterPrice, trigger: 'blur'}
        ],
        lowMktPrice: [
          {validator: this.validateLowMktPrice, trigger: 'blur'}
        ]
      }
    }
  },
  watch: {
    show: {
      handler(newVal) {
        console.log(newVal, 'newVal');
        this.dialogVisible = newVal;
        if (newVal) {
          this.goodsSelectorDialog.display();
          this.$nextTick(() => {
            // this.$refs.tables.toggleSearch(true) // 展示高级搜索
            let params_his
            try {
              const fr = localStorage.getItem('sshl__shop_fr') || 'nopre'
              const k = `${fr}@to${this.$route.path}`
              // debugger
              params_his = this.$ls.get(k) // 历史搜索数据
              if (params_his) {
                this.params = {
                  ...this.params,
                  // ...params_his.params // 这个是缓存的搜索条件  现在不需要缓存
                }
                console.log(this.params, 'this.params');
                // debugger
                this.search_type = params_his.search_type || 'gift_name'
                this.areaSelectData = params_his.areaSelectData || {
                  province: '',
                  city: '',
                  county: '',
                  town: '',
                  jd_area_state: 0
                }
                this.areaNum = params_his.areaNum || 1
              }
            } catch (e) {
              console.error(e, '--params_his error--')
            }
            if (!this.provinceList.length) {
              this.getAreaHandle('province')
            }
            if (this.areaSelectData.province && !this.cityList.length) {
              this.getAreaHandle('city', {id: this.areaSelectData.province})
            }
            if (this.areaSelectData.city && !this.countyList.length) {
              this.getAreaHandle('county', {id: this.areaSelectData.city})
            }
            if (this.areaSelectData.county && !this.townList.length) {
              this.getAreaHandle('town', {id: this.areaSelectData.county})
            }
            if (this.goodsList.length === 0 || params_his) {
              this.categories = params_his ? params_his.categories : [];
              this.GET_Goods();
              this.getNavs();
            }
            if (this.defaultData && this.defaultData.length >= 0) {
              this.chooseChangeList = JSON.parse(JSON.stringify(this.defaultData));
            } else {
              this.chooseChangeList = [];
            }
            // 省 watcher
            provinceWatcher = this.$watch('areaSelectData.province', function(newValue) {
              this.areaSelectData.city = ''
              this.areaSelectData.county = ''
              this.areaSelectData.town = ''
              if (newValue === '') {
                this.areaNum = 1
                this.getAreaHandle('province')
              } else {
                this.areaNum = 2
                this.getAreaHandle('city', {id: newValue})
              }
            })
            // 市 watcher
            cityWatcher = this.$watch('areaSelectData.city', function(newValue) {
              this.areaSelectData.county = ''
              this.areaSelectData.town = ''
              if (this.areaSelectData.province === '') return
              if (newValue === '') this.areaNum = 2;
              else {
                this.areaNum = 3
                this.getAreaHandle('county', {id: newValue})
              }
            })
            // 区 watcher
            countyWatcher = this.$watch('areaSelectData.county', function(newValue) {
              this.areaSelectData.town = ''
              if (this.areaSelectData.city === '') return
              if (newValue === '') this.areaNum = 3;
              else {
                this.areaNum = 4
                this.getAreaHandle('town', {id: newValue})
              }
            })
          })
        } else {
          this.goodsSelectorDialog.dismiss();
          // unwatcher
          provinceWatcher()
          cityWatcher()
          countyWatcher()
        }
      },
      immediate: true
    },
    dialogVisible(newVal) {
      newVal === false && this.$emit('close')
    },
    // 'defaultData': 'defaultDataChanged'
    'params.lowEnterPrice': {
      handler (newVal) {
        if (newVal) {
          this.params.lowEnterPrice = newVal.replace(/[^\d^\\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        }
      },
    },
    'params.highEnterPrice': {
      handler (newVal) {
        if (newVal) {
          this.params.highEnterPrice = newVal.replace(/[^\d^\\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        }
      }
    },
    'params.lowMktPrice': {
      handler (newVal) {
        if (newVal) {
          this.params.lowMktPrice = newVal.replace(/[^\d^\\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        }
      }
    },
    'params.highMktPrice': {
      handler (newVal) {
        if (newVal) {
          this.params.highMktPrice = newVal.replace(/[^\d^\\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        }
      }
    }
  },
  filters: {
    formatPrice(price) {
      return '￥' + String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async mounted () {
    console.log(this.defaultData, 'defaultData');
    if (sessionStorage.getItem('MixinIsFormEnterprise')) {
      this.MixinIsFormEnterprise = JSON.parse(sessionStorage.getItem('MixinIsFormEnterprise'))
    }
    API_Login.getLoginType().then(res => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem('MixinIsFormEnterprise', true)
      } else sessionStorage.setItem('MixinIsFormEnterprise', false)
    })
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    this.JDCategory.tryReload();
  },
  methods: {
    calcTableHeight() {
      return new Promise(resolve => {
        setTimeout(() => {
          // const height = (document.body.clientHeight - (10 + 30 + 54 + 72 + 55 + 20) - this.$refs.header.clientHeight);
          this.tableHeight = document.body.clientHeight - (54 + 72 + 55 + 20);
          resolve();
        });
      });
    },
    min(obj = {}, minKey = '', maxKey = '', disable0 = false) {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(min) > parseFloat(max)) {
        obj[minKey] = max;
      } else if (disable0 && min < 0) {
        obj[minKey] = 0;
      }
    },
    max(obj = {}, minKey = '', maxKey = '') {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(max) < parseFloat(min)) {
        obj[maxKey] = min;
      }
    },
    getNavs() {
      const url = this.navigationsApi
      request({
        url,
        method: 'get',
        loading: false,
        params: {client_type: 'PC'}
      }).then(response => {
        response.forEach(res => {
          res.self_goods = Foundation.getAllKey(res.url).self_goods
        })
        this.fdList = response;
      })
    },
    resetParams() {
      this.params = {
        goods_source: this.params.goods_source,
        self_goods: this.params.self_goods,
        page_no: 1,
        page_size: 20,
      }
    },
    tabClick() {
      this.resetParams();

      if (this.params.self_goods === '2') {
        this.params.goods_source = 1
        this.areaSelectData.jd_area_state = 0
        this.areaSelectData.province = ''
      } else {
        this.params.goods_source = 0
      }

      this.GET_Goods();
    },
    handleSearch (searchOpt) {
      if (this.params.highEnterPrice && this.params.lowEnterPrice > this.params.highEnterPrice) return this.$message.error('最低销售总价应小于等于最高销售总价')
      if (this.params.highMktPrice && this.params.lowMktPrice > this.params.highMktPrice) return this.$message.error('最低套餐价应小于等于最高套餐价')
      this.GET_Goods(searchOpt)
    },
    // 获取商品选择器数据
    GET_Goods (searchOpt = {}) {
      console.log(searchOpt, 'searchOpt');
      console.log(this.params, 'params');
      // debugger
      this.loading = true;
      this.showCombo && (this.params.is_combo = 0)
      if (this.supplementShopId) {
        this.params['shop_id'] = this.supplementShopId
      }
      if (searchOpt && searchOpt.saveParams) {
        try {
          const fr = localStorage.getItem('sshl__shop_fr') || 'nopre'
          const k = `${fr}@to${this.$route.path}`
          this.$ls.set(k, {
            params: {...this.params}, // 大部分搜索参数在这
            search_type: this.search_type, // 关键词的下拉
            // categories: this.categories, // 分类的选中数据
            // areaSelectData: this.areaSelectData, // 地区选择数据
            // areaNum: this.areaNum,
          })
        } catch (e) {}
      }
      // 是否自提
      this.params.delivery_method = this.delivery_method
      if (searchOpt && searchOpt.initPageNo) this.params.page_no = 1;
      // 重置搜索关键词参数
      const key = ['gift_name', 'web_gift_name', 'goods_name']
      key.forEach(item => { if (this.params[item]) this.params[item] = '' })

      this.params[this.search_type] = this.params.keywords
      const params = {}
      Object.entries(this.params).forEach(([key, value]) => {
        if (value || key === 'normalStatus') params[key] = value
      })

      const url = this.goodsApi;
      // debugger
      request({
        url,
        method: 'get',
        loading: true,
        params
      }).then(response => {
        // 这里做了兼容性处理  兼容礼包跟商品的字段
        if (Array.isArray(response?.data) && response.data.length) {
          response.data.forEach(item => {
            item.goods_id = item.id // 为了兼容 商品id
            item.goods_name = item.gift_name // 为了兼容 商品名字
            item.revise_price = item.shop_sum_price // 为了兼容 套餐
            item.mktprice = item.market_sum_price // 为了兼容 分销
            item.goodsType = 'PACK'
          })
        }
        // debugger
        this.tableData = response;
        this.$nextTick(() => {
          this.canChangeVal = false;

          this.tableData.data.forEach(e => {
            e.label_names_gift = e.label_names_gift && e.label_names_gift.replaceAll(',', '、')
            e.label_names_area = e.label_names_area && e.label_names_area.replaceAll(',', '、')
            let haveSameId = false;// 设置标志，回显已选数据期间不触发chooseChangeList赋值
            for (let index = 0; index < this.chooseChangeList.length; index++) {
              haveSameId = false;
              const ee = this.chooseChangeList[index];
              if (e.goods_id === ee.goods_id) {
                haveSameId = true;
                // 将新值赋值给旧值
                this.chooseChangeList[index] = e;
                break;
              }
            }

            this.$forceUpdate();

            if (haveSameId) {
              this.$refs?.tables?.toggleRowSelection(e, true);
            } else {
              this.$refs?.tables?.toggleRowSelection(e, false);
            }
          });
          this.canChangeVal = true;

          this.loading = false;
        })
      }).finally(() => {
        this.$emit('loading', false);
      })
    },
    // 批量挑选抛出方法
    chooseAll() {
      if (this.chooseChangeList.length) {
        this.$emit('chooseAll', this.chooseChangeList);
        this.$emit('close');
      } else {
        this.$message.error('请先选择商品');
      }
    },
    selectionChange (val) {
      if (this.canChangeVal) {
        this.chooseChangeList.push.apply(this.chooseChangeList, val);
        // 解决商品专题共用组件选择商品时会误删除的问题
        // eslint-disable-next-line no-return-assign
        this.chooseChangeList.forEach(e => e.id ? null : e.id = e.goods_id)
        for (let i = 0; i < this.chooseChangeList.length; i++) {
          for (let j = i + 1; j < this.chooseChangeList.length; j++) {
            if (this.chooseChangeList[i]['id'] === this.chooseChangeList[j]['id']) {
              this.chooseChangeList.splice(j, 1);
              j--;
            }
          }
        }
      }
    },
    handleSelect (selection, row) {
      if (!selection.includes(row)) {
        for (let i = 0; i < this.chooseChangeList.length; i++) {
          const e = this.chooseChangeList[i];
          if (e.id === row.id) {
            this.chooseChangeList.splice(i, 1);
          }
        }
      }
    },
    handleSelectAll (selection) {
      if (!selection.length) {
        const rows = this.tableData.data;
        for (let tableIndex = 0; tableIndex < rows.length; tableIndex++) {
          const row = rows[tableIndex];
          for (let i = 0; i < this.chooseChangeList.length; i++) {
            const e = this.chooseChangeList[i];
            if (e.id === row.id) {
              this.chooseChangeList.splice(i, 1);
            }
          }
        }
      }
    },
    handleSortChange({order, prop}) {
      if (order) {
        this.params.sort = `${prop}_${order.includes('asc') ? 'asc' : 'desc'}`;
      } else {
        this.params.sort = '';
      }
      this.GET_Goods();
    },
    // 挑选抛出方法
    choose(row) {
      this.$emit('choose', row)
      this.$emit('close')
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_Goods();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_Goods();
    },
    /** 当默认数据发生改变 */
    defaultDataChanged(newVal) {
      if (newVal && newVal.length >= 0) {
        this.chooseChangeList = this.defaultData;
      } else {
        this.chooseChangeList = [];
      }
    },
    createTimeFilterChange (date) {
      // eslint-disable-next-line no-return-assign
      if (!date) return this.params.create_time_start = this.params.create_time_end = ''
      const start = date[0] / 1000
      let end = date[1] / 1000
      end = end + 24 * 60 * 60
      if (Array.isArray(date)) {
        this.params.create_time_start = start
        this.params.create_time_end = end
      } else {
        this.params.create_time_start = ''
        this.params.create_time_end = ''
      }
    },
  }
}
